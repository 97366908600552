var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workspace-nav"},[_c('div',{staticClass:"py-9 workspace-nav__list"},[_c('p',{staticClass:"px-6 mb-7 fs-14 text-uppercase lightGrey--text text--darken-3"},[_vm._v(" Workspaces ")]),_c('v-text-field',{staticClass:"px-6 mt-0 mb-3",attrs:{"label":"Search Workspaces","color":"darkGrey","clearable":"","dense":"","flat":""},model:{value:(_vm.searchWorkspace),callback:function ($$v) {_vm.searchWorkspace=$$v},expression:"searchWorkspace"}}),(_vm.filteredWorkspaces.length)?_c('v-list',{staticClass:"py-0 px-3 d-flex flex-column gap-2",attrs:{"dark":""}},[_vm._l((_vm.filteredWorkspaces),function(workspace){return _c('v-list-item',{key:workspace.id,class:[
          {'v-list-item--active': workspace.id == _vm.activeWorkspaceId},
          'px-3 py-3 justify-space-between'
        ],attrs:{"dark":""}},[_c('div',{staticClass:"d-flex align-center gap-3 pointer",on:{"click":function () { return _vm.changeWorkspace(workspace.id); }}},[_c('WorkspaceBubble',{class:["fs-11", _vm.customBgColor(workspace.createdAt)],attrs:{"size":25}},[_c('span',[_vm._v(_vm._s(workspace.name.substring(0, 2)))])]),_c('p',{staticClass:"ma-0 fs-16 black--text"},[_vm._v(" "+_vm._s(workspace.name)+" ")])],1),_c('div',{staticClass:"d-flex align-center gap-3"},[(workspace.verified && _vm.useToggleToMoveToVerifiedArea)?_c('i',{staticClass:"nav-svg-icon icon-verified green"}):_vm._e(),(_vm.activeWorkspaceId === workspace.id && _vm.canPermissionAdminConsole)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":{ name: 'workspace-adminpanel-members', params: { wId: _vm.activeWorkspaceId, field: 'members' }},"target":"_blank"}},[_c('v-icon',_vm._g(_vm._b({attrs:{"size":20,"color":"black"}},'v-icon',attrs,false),on),[_vm._v(" mdi-briefcase ")])],1)]}}],null,true)},[_c('span',[_vm._v("Admin Console")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[(workspace.id != _vm.PERSONAL_WORKSPACE_ID)?_c('WorkspaceEditorDialog',{attrs:{"type":_vm.TYPE_EDIT,"workspace":workspace}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Edit Workspace")])])],1)])}),_c('v-list-item',{staticClass:"px-3 py-3"},[_c('WorkspaceEditorDialog',{staticClass:"flex-grow-1 pointer"})],1)],2):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"px-6 py-9 pointer",on:{"click":function($event){return _vm.$emit('close-drawer')}}},[_c('v-icon',{attrs:{"color":"black","size":"30"}},[_vm._v(" mdi-arrow-left-circle ")]),_c('span',{staticClass:"fs-16 ml-3 vertical-middle black--text"},[_vm._v(" Minimize Menu ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }