<template>
  <v-avatar
    class="workspace-bubble white--text"
    v-bind="$attrs">
    <slot />
  </v-avatar>
</template>
<script>
export default {
  name: 'WorkspaceBubble',
};
</script>
<style lang="scss" scoped>
.workspace-bubble {
  background-color: var(--bg-color);
  border: none;
  text-transform: uppercase;
  word-wrap: break-word;
}
</style>