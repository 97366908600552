import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import { WORKSPACE_PERMISSION } from '@/constants/userPermissions';

export default {
  computed: {
    ...mapGetters('Workspace', ['getActiveWorkspace']),
    ...mapGetters('FeatureFlags', ['lessRefreshing']),
    ...mapState('Workspace', ['workspacesList', 'activeWorkspaceId']),

    alphabeticWorkspaceList() {
      if (!this.workspacesList.length) return [];
      const list = this.workspacesList.filter((w) => w.id !== this.activeWorkspaceId);
      list.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1);
      list.unshift(this.getActiveWorkspace);
      return list;
    },
    recentWorkspaceList() {
      if (!this.workspacesList.length)
        return [];

      const list = this.workspacesList.filter((w) => w.id !== this.activeWorkspaceId);
      list.sort((a, b) => b.createdAt - a.createdAt);
      list.unshift(this.getActiveWorkspace);
      return list;
    },
  },
  methods: {
    ...mapActions(['resetStates']),
    ...mapActions('Projects', ['clearData', 'getData']),
    ...mapActions('ManageSubscriptions', ['removeSubscriptions']),

    async changeWorkspace(wId, forceRefresh) {
      const { $router, activeWorkspaceId } = this;
      if (this.lessRefreshing) {
        if (!forceRefresh && wId === activeWorkspaceId) return;
      }

      await $router.push({
        name: 'projects',
        params: {
          wId,
        },
      }).catch(() => {
      });

      this.removeSubscriptions([WORKSPACE_PERMISSION]);
      this.resetStates(['Libraries', 'Collections']);

      if (this.lessRefreshing) {
        const RequestAbortController = (await import('@/utils/RequestAbortController')).default;
        this.abortController = new RequestAbortController();
        this.getData({
          abortController: this.abortController,
        });
      }
    },
  },
};