<template>
  <div
    class="px-0"
    @click="showWorkspaceModal=true">
    <app-dialog
      v-model="showWorkspaceModal"
      :value.sync="showWorkspaceModal"
      width="500"
      content-class="v-dialog__form">
      <template v-slot:activator="{ on }">
        <template v-if="isCreateType">
          <p
            data-test="create_new_workspace_menu_button"
            class="px-0 py-3 ma-0 text-center border-dashed">
            <v-icon
              color="blue"
              size="25"
              v-on="on">
              mdi-plus-circle
            </v-icon>
          </p>
        </template>
        <v-icon
          v-else-if="isEditType"
          data-test="workspace_setting_button"
          class="op-1 pointer"
          color="black"
          size="20"
          v-on="on">
          mdi-settings
        </v-icon>
      </template>
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
            <span v-if="isCreateType">Create New Workspace</span>
            <span
              v-else-if="isEditType"
              class="workspaces__add-form-title">
              Edit Workspace
              <v-tooltip
                top>
                <template #activator="{ on, attrs }">
                  <span
                    class="workspaces__add-form-title"
                    v-bind="attrs"
                    v-on="on">
                    {{ workspace.name }}
                  </span>
                </template>
                <span>{{ workspace.name }}</span>
              </v-tooltip>
            </span>
          </div>
          <span
            class="sourcery__icon-wrapper black"
            @click="showWorkspaceModal=false">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="workspaceEditorForm">
            <v-row>
              <v-col
                md="12"
                cols="12">
                <v-text-field
                  v-model="workspaceForm.name"
                  :rules="requiredRules"
                  placeholder="Workspace name"
                  single-line
                  :disabled="!canWorkWithEditWorkspaceInfo"
                  dense
                  required
                  class="mb-6" />
                <v-textarea
                  v-model="workspaceForm.description"
                  :rows="3"
                  no-resize
                  dense
                  :disabled="!canWorkWithEditWorkspaceInfo"
                  placeholder="Description"
                  class="mb-6" />
                <div
                  style="display: grid; gap: 13px; justify-content: start;">
                  <div>
                    <file-upload
                      ref="uploadWorkspaceLogo"
                      v-model="logoFile"
                      :input-id="generateIdForLogo"
                      :multiple="false"
                      :drop-directory="false"
                      :drop="false"
                      :disabled="!canWorkWithEditWorkspaceInfo"
                      @input="addWorkspaceLogo"
                      @input-filter="inputFilter" />
                    <div
                      class="badge-cirle-container">
                      <v-icon
                        v-if="imgPreviewUrl && canWorkWithEditWorkspaceInfo"
                        x-small
                        class="badge-cirle-container__close-icon"
                        style="position: absolute;right: -6px;top:-4px;"
                        @click="removeWorkspaceLogo">
                        mdi-close-circle
                      </v-icon>
                      <img
                        v-if="imgPreviewUrl"
                        class="logo-image"
                        :src="imgPreviewUrl">
                      <v-icon
                        v-else
                        size="98"
                        color="blue">
                        mdi-image
                      </v-icon>
                      <label
                        :for="generateIdForLogo"
                        class="small-p">
                        <div
                          v-if="canWorkWithEditWorkspaceInfo"
                          class="circle-button-container circle-button-container__add-avatar">
                          <v-icon>
                            mdi-plus-circle-outline
                          </v-icon>
                        </div>
                      </label>
                    </div>
                  </div>
                  <p
                    class="small-p d-flex align-center">
                    Upload logo
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex justify-end gap-3">
          <v-btn
            outlined
            @click="showWorkspaceModal = false">
            Cancel
          </v-btn>
          <app-btn
            :disabled="!canWorkWithEditWorkspaceInfo"
            color="blue"
            btn-class="white--text"
            elevation="0"
            @click="validate">
            <template v-if="isCreateType">
              Save
            </template>
            <template v-if="isEditType">
              Update
            </template>
          </app-btn>
        </v-card-actions>
      </v-card>
    </app-dialog>
  </div>
</template>
<script>
import {
  mapActions, mapMutations,
  mapState, mapGetters,
} from 'vuex';
import { isEmpty } from 'lodash';
import { Storage } from 'aws-amplify';
import FileUpload from 'vue-upload-component';
import {
  TYPE_CREATE,
  TYPE_EDIT,
} from '@/constants';
export default {
  name: 'WorkspaceEditorDialog',
  components: {
    FileUpload,
  },
  props: {
    type: {
      type: String,
      default: TYPE_CREATE,
    },
    workspace: {
      type: Object,
      default: () => ({
      }),
    },
  },
  data: () => ({
    TYPE_CREATE,
    TYPE_EDIT,
    showWorkspaceModal: false,
    workspaceForm: {
      workspaceId: '',
      name: '',
      description: '',
      logo: '',
    },
    logoFile: [],
    logoUrl: '', // only for edit
    requiredRules: [v => !!v || 'This field is required'],
  }),
  computed: {
    ...mapState('Workspace', ['workspaceTypes']),
    ...mapGetters('ManageFiles', ['getFileByKey']),
    ...mapGetters('FeatureFlags', ['useIncreaseCaching']),
    ...mapGetters('UserRoles', ['canEditWorkspaceInfo']),
    ...mapState({
      showSpinner: state => state.showSpinner,
    }),
    getLogoFile() {
      return this.logoFile[0]?.file;
    },
    imgPreviewUrl() {
      const { getLogoFile, logoUrl, isEditType } = this;
      if (isEditType && !this.logoFile.length) {
        return logoUrl;
      }
      return getLogoFile ? URL.createObjectURL(getLogoFile) : '';
    },
    isCreateType() {
      return this.type == TYPE_CREATE;
    },
    isEditType() {
      return this.type == TYPE_EDIT;
    },
    getAddForm() {
      // eslint-disable-next-line no-unused-vars
      const { workspaceId, ...addForm } = this.workspaceForm;
      return addForm;
    },
    generateIdForLogo() {
      return 'workspace-logo'.concat(this.workspace?.id);
    },
    canWorkWithEditWorkspaceInfo() {
      if (this.isCreateType) {
        return true;
      }
      return this.canEditWorkspaceInfo(this.workspace.type);
    },
  },
  watch: {
    showWorkspaceModal: {
      async handler(isOpened) {
        if (!isEmpty(this.workspace) && this.isEditType && isOpened) {
          const { name, description, logo, id } = this.workspace;
          this.workspaceForm = {
            name,
            description,
            workspaceId: id,
            logo,
          };
          if (logo) {
            if (this.useIncreaseCaching) {
              await this.parseFile({
                key: logo,
                config: {
                  level: 'public',
                },
              });
              this.logoUrl = this.getFileByKey(logo);
            } else {
              this.logoUrl = await Storage.get(logo,
                {
                  level: 'public',
                });
            }
          }
          return;
        }
        this.workspaceForm = {
          workspaceId: '',
          name: '',
          description: '',
          logo: '',
        };
      },
    },
  },
  methods: {
    ...mapActions('Workspace', ['createWorkspace', 'editWorkspace']),
    ...mapActions('ManageFiles', ['parseFile', 'removeFilesFromCache']),
    ...mapMutations(['spinner']),
    inputFilter(newFile, oldFile, prevent) {
      let val;
      if (newFile && !oldFile) {
        if (newFile.file && !newFile.file.type.includes('image')) {
          val = prevent();
        }
      }
      return val;
    },
    addWorkspaceLogo() {
      const file = this.getLogoFile;
      if (file) {
        this.workspaceForm.logo = file;
      }
    },
    removeWorkspaceLogo() {
      if (this.isEditType) {
        this.logoUrl = '';
      }
      this.logoFile = [];
      this.workspaceForm.logo = '';
    },
    async validate() {
      if (this.showSpinner) {
        return;
      }
      if (!this.canWorkWithEditWorkspaceInfo) {
        return;
      }
      if (this.$refs.workspaceEditorForm.validate()) {
        this.spinner('true');
        this.isCreateType ? await this.createWorkspace(this.getAddForm) : await this.editWorkspace(this.workspaceForm);
        this.spinner(false);
        this.showWorkspaceModal = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.border-dashed {
  border-radius: 10px;
  border: 1px rgba(12, 12, 12, 0.25) dashed;
}
.required-field {
  color: red;
}
::v-deep .v-text-field__slot {
  textarea {
    overflow: auto !important;
  }
}
.add-images-wrapper {
  cursor: pointer;
  display: block;
  height: 50px;
  color: #979797;
  &__add-images {
    display: flex;
    .icon {
      font-size: 45px;
    }
  }
}
.img-loaded-wrapper {
  position: relative;
  max-width: 50%;
  img {
    width: 100%;
    height: auto;
  }
  &__remove-icon {
    display: none !important;
  }
  &:hover {
    .img-loaded-wrapper__remove-icon {
      display: block !important;
      position: absolute;
      right: -16px;
      top: -12px;
    }
  }
}
.badge-cirle-container {
  position: relative;
}
.logo-image {
  width: 160px;
  border-radius: 5px;
}
</style>
